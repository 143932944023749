import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import config from "../config.tsx";

type ContentTextPageType = {
	title: string;
	text: string;
}

const ContentTextPage = ({ id }) => {
	const { i18n } = useTranslation();
	const [contentData, setContentData] = useState<ContentTextPageType>({ title: '', text: '' });

	useEffect(() => {
		const loadContentData = async () => {
			try {
				const response = await fetch(config.API_URL + "/text/page/" + id, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': i18n.language
					}
				});

				if (response.ok) {
					setContentData(await response.json())
				}
			} catch { }
		};

		setContentData({ title: '', text: '' });
		loadContentData();
	}, [id, i18n.language]);

	return (
		<main className="post-page">
			<div className="container">
				<h1 className="inner-h1">{contentData.title}</h1>
				<div className="news-slider-decoration">
					<img src="/images/icon/news-decoration-side.svg" alt="decor" width="18" height="12" className="decor-side" />
					<hr />
					<img src="/images/icon/news-decoration.svg" alt="decor" width="36" height="21" />
					<hr />
					<img src="/images/icon/news-decoration-side.svg" alt="decor" width="18" height="12" className="decor-side" />
				</div>
				<div className="text-area">
					<div dangerouslySetInnerHTML={{ __html: contentData.text }} />
				</div>
			</div>
		</main>
	);
};

export default ContentTextPage;