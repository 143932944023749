import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import config from '../config.tsx';
import { useAppContext } from '../context/app-context.tsx';

type News = {
	date: string,
	title: string,
	content: string
};

const news: News[] = [
	{
		date: "15.06.25",
		title: "Установлено обновление Shine Maker!",
		content: "Глобальное обновление Master Class — это не только долгожданный класс, но и жаркие битвы за новую территорию"
	}
];

function Home() {
	const { serverList, updateServerList } = useAppContext();
	const [newsList, setNewsList] = useState<News[]>([]);

	useEffect(() => {
		setNewsList(news);
		updateServerList();
	}, [updateServerList]);

	useEffect(() => {
		setNewsList(news);
	}, []);

	return (
		<main>
			<section className="hero">
				<div className="container">
					<div className="hero-info">
						<h1>
							<span className="gold">открытие</span>
							<span className="silver">нового сервера</span>
						</h1>
						<div className="hero-description">
							Приветствуем Вас на сайте Essence проекта Genesis World!
							<p className="start-date"><span>скоро</span></p>
						</div>
						<div className="hero-start">
							<Link to={config.AUTHORIZATION_URL + '/register'} className="cta-start">
								<img src="images/icon/cta-arrows.svg" alt="arrow" className="cta-start-arrows" />
								<span>Начать игру</span>
								<img src="images/icon/cta-arrows.svg" alt="arrow" className="cta-start-arrows" />
							</Link>
						</div>
					</div>
					<div className="girl-elf">
						<img src="images/hero-elf.webp" alt="" />
					</div>
				</div>
			</section>
			<section className="servers">
				<div className="servers-list container">
					{serverList.map((server, index) => (
						<div className="server" key={index}>
							<div className="server-header">
								<img src="images/icon/server1.svg" alt={server.name} width="40" height="40" />
								<div className="server-info">
									<p className="server-rate">x {server.rate}</p>
									<p className="server-name">{server.name}</p>
									<p className="server-online">
										Online: <span className="online">{server.online}</span>
									</p>
								</div>
							</div>
							<Link to={'/server-info/' + server.id} className="server-button">
								О сервере
								<img src="images/icon/arrow.svg" alt="arrow" width="9" height="9" />
							</Link>
						</div>
					))}
				</div>
			</section>
			<section className="news-index container">
				<div className="news-slider-decoration">
					<img src="images/icon/news-decoration-side.svg" alt="decor" width="18" height="12" className="decor-side" />
					<hr />
					<img src="images/icon/news-decoration.svg" alt="decor" width="36" height="21" />
					<hr />
					<img src="images/icon/news-decoration-side.svg" alt="decor" width="18" height="12" className="decor-side" />
				</div>
				<div className="news-slider">
					{newsList.map((news, index) => (
						<Link key={index} to="/news" className="post" style={{ backgroundImage: `url(images/post1.webp)` }}>
							<p className="date">{news.date}</p>
							<div className="post-info">
								<h2>
									{news.title}
									<img src="images/icon/arrow.svg" alt="arrow" width="12" height="12" />
								</h2>
								<p>{news.content}</p>
							</div>
						</Link>
					))}
				</div>
				<Link to="/news" className="all-news">
					Все новости
					<img src="images/icon/angle-arrow.svg" alt="arrow" height="20" />
				</Link>
			</section>
			<div className="middle-index">
				<section className="advantages container">
					<div className="section-divider">
						<hr />
						<img src="images/icon/section-divider.svg" alt="decor" />
						<hr />
					</div>
					<h2>Преимущества</h2>
					<img src="images/icon/pluses-decoration.svg" alt="decor" width="215" height="14" />
					<div className="advantages-list">
						<div className="advantage-item">
							<div className="advantage-item-content">
								<img src="images/advantage.webp" alt="advantage" width="340" height="340" />
								<h3>Новые и обновленные профессии!</h3>
								<div className="advantage-item-description">
									<p>
										Насладись игрой на новом классе Варкс. Также вас ждут обновленный Кардинал, Мастер Стихий и Аватар. Выбери профессию под свой стиль игры!
									</p>
								</div>
							</div>
						</div>
						<div className="advantage-item">
							<div className="advantage-item-content">
								<img src="images/advantage.webp" alt="advantage" width="340" height="340" />
								<h3>Качественная сборка и уникальный контент!</h3>
								<div className="advantage-item-description">
									<p>
										Мы готовы представить вам самый актуальный контент, адаптированный под наш сервер.
									</p>
									<p>
										Новые профессии, обновленная система татуировок, новые драгоценные камни, переработанная Невероятная зона, Исследование Подземелий и Лаборатория Адена.
									</p>
								</div>
							</div>
						</div>
						<div className="advantage-item">
							<div className="advantage-item-content">
								<img src="images/advantage.webp" alt="advantage" width="340" height="340" />
								<h3>Авторские улучшения и модификации для комфортной игры!</h3>
								<div className="advantage-item-description">
									<p>
										Оффлайн фарм, массовый синтез и открытие предметов, система гаранта, автоматический поиск предметов в обычном создании и свойств в системе зачарования, расширенная информация для командного канала и многое другое.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="how-start container">
					<h2>Как начать играть</h2>
					<div className="steps">
						<div className="step">
							<img src="images/icon/step1.svg" alt="step" width="77" height="69" />
							<p>Регистрация<br />аккаунта</p>
						</div>

						<div className="step">
							<img src="images/icon/step2.svg" alt="step" width="77" height="69" />
							<p>Загрузка<br />файлов</p>
						</div>

						<div className="step">
							<img src="images/icon/step3.svg" alt="step" width="77" height="69" />
							<p>можно начинать<br />играть</p>
						</div>
					</div>
					<Link to={config.AUTHORIZATION_URL + '/register'} className="cta-decoration">
						<img src="images/icon/corner-decoration.svg" alt="decor" className="decor" />
						<span>Начать играть</span>
						<img src="images/icon/corner-decoration.svg" alt="decor" className="decor" />
					</Link>
				</section>
			</div>
		</main>
	);
}

export default Home;