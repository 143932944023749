import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import config from '../config.tsx';

type SocialMediaLinks = {
	discord: string;
	telegram: string;
};

type ServerType = {
	id: number,
	name: string,
	rate: number,
	online: boolean
};

interface AppContextProps {
	socialMedia: SocialMediaLinks;
	serverList: ServerType[];
	updateServerList: () => void;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const useAppContext = () => {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error('useServerContext must be used within a AppProvider');
	}

	return context;
};

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [socialMedia, setSocialMedia] = useState<SocialMediaLinks>({
		telegram: '',
		discord: ''
	});

	const [serverList, setServerList] = useState<ServerType[]>([]);

	const updateServerList = useCallback(async () => {
		try {
			const response = await fetch(config.API_URL + "/game/server-list", {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			});
	
			if (response.ok) {
				setServerList(await response.json());
			}
		} catch {}
	}, []);

	useEffect(() => {
		updateServerList();
	}, [updateServerList]);

	useEffect(() => {
		fetch('/config.json')
			.then(response => response.json())
			.then(data => setSocialMedia(data));
	}, []);

	return (
		<AppContext.Provider value={{
			socialMedia,
			serverList,
			updateServerList
		}}>
			{children}
		</AppContext.Provider>
	);
};