import React from 'react';

import App from '../App.tsx';
import Home from '../pages/home.tsx';
import ServerInfo from '../pages/server-info.tsx';
import FileDownload from '../pages/file-download.tsx';
import News from '../components/news/news-list.tsx';
import NewsDetails from '../components/news/news-details.tsx';
import ContentTextPage from '../components/content-text-page.tsx';
import { RouteObject } from 'react-router-dom';

const AppRoute : RouteObject[] = [
	{
		path: '/',
		element: <App />,
		children: [
			{ path: '', element: <Home /> },
			{ path: 'server-info/:id', element: <ServerInfo /> },
			{ path: 'server-info/:id/:type', element: <ServerInfo /> },
			{ path: 'download', element: <FileDownload /> },
			{ path: 'news', element: <News /> },
			{ path: 'news/:type', element: <News /> },
			{ path: 'news/:type/:id', element: <NewsDetails /> },
			{ path: 'terms', element: <ContentTextPage id="1" /> },
			{ path: 'agreement', element: <ContentTextPage id="2" /> },
			{ path: 'policy', element: <ContentTextPage id="3" /> },
			{ path: '*', element: <Home /> }
		],
	}
];

export default AppRoute;