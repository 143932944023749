import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useRoutes } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import AppRoute from './config/app-route.tsx';
import { AppProvider } from './context/app-context.tsx';

import './i18n.tsx';

function App() {
	let element = useRoutes(AppRoute);
	return element;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<AppProvider>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
