import React from 'react';
import { Link, Outlet } from 'react-router';
import { useTranslation } from 'react-i18next';
import HomeHeader from './components/home/header.tsx';
import HomeFooter from './components/home/footer.tsx';

import './scss/style.scss';
import './scss/styles.css';
import config from './config.tsx';

function App() {
	const { i18n, t } = useTranslation();

	const toggleMenu = () => {
        const burger = document.querySelector(".burger");
		const menu = document.querySelector(".menu");
		burger?.classList.toggle("open");
		menu?.classList.toggle("open");
    };

	const changeLanguage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();
		i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru');
	};

	return (
		<>
			<HomeHeader />
			<Outlet />
			<HomeFooter />
			<div className="footer-bottom">
				<div className="container">
					<Link to="https://gridkit.ru/" target="_blank">Design by GridKit.ru</Link>
				</div>
			</div>
			<div className="mobile-navigation">
				<div className="language-login">
					<Link to="#" onClick={changeLanguage} className="language">{i18n.language === 'ru' ? 'RU' : 'EN'}</Link>
					<Link to={config.AUTHORIZATION_URL} className="login-cta">
						<svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								className="arrow"
								d="M7.60355 11.3964L11.4129 7.58712C11.6016 7.39839 11.6088 7.09472 11.4293 6.89723L7.61997 2.70697C7.31255 2.3688 6.75 2.58629 6.75 3.0433V4.75C6.75 5.02614 6.52614 5.25 6.25 5.25H2.25C1.69772 5.25 1.25 5.69772 1.25 6.25V8.25C1.25 8.80229 1.69772 9.25 2.25 9.25H6.25C6.52614 9.25 6.75 9.47386 6.75 9.75V11.0429C6.75 11.4883 7.28857 11.7114 7.60355 11.3964Z"
								fill="#FFBE45"
							/>
							<path
								className="border"
								d="M11.25 0.75H12.75C14.4069 0.75 15.75 2.09315 15.75 3.75V10.25C15.75 11.9069 14.4069 13.25 12.75 13.25H11.25M6.75 3.0433V4.75C6.75 5.02614 6.52614 5.25 6.25 5.25H2.25C1.69772 5.25 1.25 5.69772 1.25 6.25V8.25C1.25 8.80228 1.69772 9.25 2.25 9.25H6.25C6.52614 9.25 6.75 9.47386 6.75 9.75V11.0429C6.75 11.4883 7.28857 11.7114 7.60355 11.3964L11.4129 7.58712C11.6016 7.39839 11.6088 7.09472 11.4293 6.89723L7.61997 2.70697C7.31255 2.3688 6.75 2.58629 6.75 3.0433Z"
								stroke="#FFBE45"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						{t('footer.enter')}
					</Link>
					<div className="burger" onClick={toggleMenu}><span></span><span></span><span></span></div>
				</div>
			</div>
		</>
	);
}

export default App;